import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  Title,
  NotFoundBackground,
  NavButton,
} from "./notFound.styles";

const NotFound = () => {
  const navigate = useNavigate();

  const goTo = (url) => {
    navigate(url);
  };

  return (
    <Container>
      <NotFoundBackground />
      <Content>
        <Title>Ups... Al parecer esta sección no existe!</Title>
        <NavButton onClick={() => goTo("/")}>
          <p>Ir al Inicio</p>
        </NavButton>
      </Content>
    </Container>
  );
};

export default NotFound;
