import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  Links,
  Logo,
  ContainerLink,
  BurgerContainer,
  BurgerMenuBar,
} from "./navBar.styles";
import ModalMenu from "../modalMenu/ModalMenu";
import Modal from "../modal/Modal";
import logo from "../../assets/images/ac_logo.svg";
import GenericNavLink from "../genericNavLink/GenericNavLink";

const NavBar = () => {
  const navigate = useNavigate();

  const goTo = (url) => {
    navigate(url);
  };

  const links = [
    {
      text: "Services",
      link: "services",
    },
    {
      text: "Projects",
      link: "projects",
    },
    {
      text: "How we work",
      link: "howWeWork",
    },
    {
      text: "Who we are",
      link: "whoWeAre",
    },
    {
      text: "Contact",
      link: "contact",
    },
  ];

  return (
    <Container>
      <Content>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
        <Links>
          {links.map(({ text, link }) => (
            <GenericNavLink key={link} text={text} link={link} />
          ))}
        </Links>

        <Modal
          activator={({ setShow }) => (
            <BurgerContainer onClick={() => setShow((state) => !state)}>
              <BurgerMenuBar />
              <BurgerMenuBar />
              <BurgerMenuBar />
            </BurgerContainer>
          )}
        >
          <ModalMenu />
        </Modal>
      </Content>
    </Container>
  );
};

export default NavBar;
