import React from "react";
import { useNavigate } from "react-router-dom";
import { animated, useTrail } from "react-spring";
import {
  Container,
  ButtonsContainer,
  MenuButton,
  Button,
  Text,
} from "./modalMenu.styles";

const ModalMenu = ({ toggle, hide }) => {
  const navigate = useNavigate();

  const scroll = (view) => {
    document.getElementsByName(view)[0].scrollIntoView({ behavior: "smooth" });
  };

  const config = { mass: 5, tension: 2000, friction: 200 };

  const links = [
    {
      text: "Services",
      link: "services",
    },
    {
      text: "Projects",
      link: "projects",
    },
    {
      text: "How we work",
      link: "howWeWork",
    },
    {
      text: "Who we are",
      link: "whoWeAre",
    },
    {
      text: "Contact",
      link: "contact",
    },
  ];

  const trail = useTrail(links.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });

  const handleClick = (link) => {
    scroll(link);
    hide();
  };

  return (
    <Container onClick={() => hide()}>
      <ButtonsContainer>
        {trail.map(({ x, height, ...rest }, index) => (
          <MenuButton
            key={index}
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <Button onClick={() => handleClick(links[index].link)}>
              <animated.div key={links[index].text + "rand"} style={{ height }}>
                <Text>{links[index].text}</Text>
              </animated.div>
            </Button>
          </MenuButton>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

export default ModalMenu;
