import React from "react";
import { GenericContainer, GenericContent } from "../../global.styles";
import {
  Project,
  TitleContainer,
  Title,
  ButtonContainer,
  Button,
  TitleSection,
  Content,
  ProjectsContainer,
  ProjectImg,
  ProjectDescription,
  DataContainer,
} from "./projects.styles";

import project1 from "../../assets/images/kickads_photo.png";
import project2 from "../../assets/images/zucarello_photo.png";
import project3 from "../../assets/images/compass_photo.png";
import { GenericButton } from "../button/Button";

const Projects = () => {
  return (
    <GenericContainer name="projects">
      <Content>
        <TitleSection>Completed Projects</TitleSection>
        <ProjectsContainer>
          <Project>
            <ProjectImg src={project1} />
            <DataContainer>
              <TitleContainer>
                <Title>Kickads</Title>
                {/* <ButtonContainer>
                                <GenericButton text="View!"/>
                            </ButtonContainer> */}
              </TitleContainer>
              <ProjectDescription>
                We worked together with the brilliant team at Kickads, a mobile
                advertising company, supporting them in their technological area
                by developing and maintaining their applications. We always
                considered the best options to bring all their innovative ideas
                and projects to life, with the goal of revolutionizing cloud
                advertising by creating dynamic and engaging advertising,
                eliminating the concept of "annoying ads."
              </ProjectDescription>
            </DataContainer>
          </Project>

          <Project>
            <ProjectImg src={project2} />
            <DataContainer>
              <TitleContainer>
                <Title>Zucarello & Associates</Title>
                {/* <ButtonContainer>
                                <GenericButton text="View!"/>
                            </ButtonContainer> */}
              </TitleContainer>
              <ProjectDescription>
                Zucarello & Associates is a law firm that provides legal
                solutions. Together, we created their brand identity and
                developed their commercial website from scratch, taking into
                account the type of service and the professional image required
                for their client presentation. We delivered a simple and fast
                product, built with technology that's designed for future
                scalability.
              </ProjectDescription>
            </DataContainer>
          </Project>

          <Project>
            <ProjectImg src={project3} />
            <DataContainer>
              <TitleContainer>
                <Title>Compass</Title>
                {/* <ButtonContainer>
                                <GenericButton text="View!"/>
                            </ButtonContainer> */}
              </TitleContainer>
              <ProjectDescription>
                For this project, we worked with Abeceb, an economics and
                business consulting firm, developing and maintaining an internal
                backoffice product to manage all their economic data, as well as
                a public-facing product. Users can perform various actions, such
                as browsing different economic data sets, generating data sheets
                with text and graphics, and even downloading all this data to
                Excel. Using Power Query technology, the data stays updated
                automatically without the need to generate new reports to stay
                current.
              </ProjectDescription>
            </DataContainer>
          </Project>
        </ProjectsContainer>
      </Content>
    </GenericContainer>
  );
};

export default Projects;
