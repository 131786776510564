import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

import { GenericContent } from "../../global.styles";
import {
  Card,
  CardsContainer,
  Container,
  Content,
  CardDescription,
  CardImg,
  CardData,
  CardTitle,
  SocialLink,
  Title,
} from "./whoWeAre.styles";

import whoWeAreBackground from "../../assets/images/backgrounds/whoWeAreBackground.svg";
import cardImgLucho from "../../assets/images/LuchoPerfil2.svg";
import cardImgLeo from "../../assets/images/LeoPerfil2.svg";

import linkedinLogo from "../../assets/icons/linkedin.svg";

const Social = ({ text, link }) => {
  const [on, toggle] = useState(false);
  const animation = useSpring({ transform: on ? "scale(1.1)" : "scale(1)" });

  return (
    <SocialLink href={link} target="_blank">
      {text}
    </SocialLink>
  );
};

const WhoWeAre = () => {
  return (
    <Container src={whoWeAreBackground} name="whoWeAre">
      <Content>
        <Title>Who we are?</Title>
        <CardsContainer>
          <Card src={cardImgLucho}>
            <CardImg />
            <CardData>
              <CardDescription> Founder </CardDescription>
              <CardTitle> Luciano Lupo </CardTitle>
              <Social
                link="https://www.linkedin.com/in/lucianolupo/"
                text="Linkedin"
              />
            </CardData>
          </Card>
        </CardsContainer>
      </Content>
    </Container>
  );
};

export default WhoWeAre;
