import styled from 'styled-components';
import { animated } from 'react-spring';
import { GenericButton, GenericContainer, variables } from '../../global.styles'

export const Container = styled(GenericContainer)`
    position:fixed;
    width:100vw;
    height:100vh;
    z-index:1;
    background-color:rgba(0,0,0,.8);

`

export const Content = styled.div`
    width:100%;
    max-width:1200px;
    background-color:transparent;
    display:flex;
    justify-content:space-between;
`


export const HamburgerContainer = styled.div`
    display:none;
    width:40px;
    height:40px;
    box-shadow: 0 2px 10px rgba(0,0,0,.3);

    @media (max-width:1000px){
        display:flex;
    }


`
export const ButtonsContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    width: 100%;
    align-items: center;

`


export const MenuButton = styled(animated.div)`

    width:100%;
    max-width:300px;
    padding: 8px 32px;
    text-align:center;

`
export const Button = styled.div`
    height:50px;

`

export const Text = styled.p`
    font-family:'Poppins';
    font-weight:800;
    color:#fdfdfd;

    @media (max-width: ${variables.medium}px) {
        font-size:28px;
    }
`