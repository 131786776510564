
import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';


export const Container = styled.div`
    margin: 0 10px;
    cursor:pointer;
`

export const Text = styled.p`
    font-family:'Poppins';
    font-weight:400;
    color:#fdfdfd;

    @media (max-width: ${variables.medium}px) {
        font-size:32px;
    }
`

export const BottomLine = styled.div`
    height:2px;
    width:100%;
    background-color:#f1f1f1;
    margin-bottom: 15px;
`