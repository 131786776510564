import React, { useState, useEffect } from "react";
import axios from "axios";
import { GenericContainer } from "../../global.styles";
import { GenericButton } from "../button/Button";
import {
  TextBox,
  Input,
  TextArea,
  Form,
  Label,
  Content,
  TitleSection,
} from "./contact.styles";
import { useForm } from "react-hook-form";

const queryDataInitialState = {
  name: "",
  mail: "",
  query: "",
};

const saveQuery = async (data) => {
  try {
    const response = await axios.post(
      "https://us-central1-fir-cloudfunctions-f54bd.cloudfunctions.net/api/sendMail",
      data
    );
    return response;
  } catch (e) {
    return e;
  }
};

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState(queryDataInitialState);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: queryDataInitialState,
  });

  useEffect(() => {
    const checkMobile = () => {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
          navigator.userAgent.substr(0, 4)
        )
      );
    };

    setIsMobile(checkMobile());
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const resp = await saveQuery(data);
      if (resp) {
        alert("Your message was sent successfully!");
        setQueryData(queryDataInitialState);
      } else {
        alert("Failed to send message, please try again later!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to send message, please try again later!");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "query" && value.length > 256) {
      return;
    }

    setQueryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <GenericContainer name="contact">
      <Content>
        <TitleSection>Send us a message!</TitleSection>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextBox>
            <Input
              placeholder="Name..."
              type="text"
              {...register("name", { required: true })}
              onChange={handleInputChange}
              value={queryData.name}
            />
            <Label>{errors.name && <p>Name is required!</p>}</Label>
          </TextBox>

          <TextBox>
            <Input
              placeholder="Email..."
              type="text"
              {...register("mail", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              onChange={handleInputChange}
              value={queryData.mail}
            />
            <Label>{errors.mail && <p>A valid email is required!</p>}</Label>
          </TextBox>

          <TextBox>
            <TextArea
              placeholder="Message..."
              {...register("query", {
                required: true,
                maxLength: 256,
              })}
              onChange={handleInputChange}
              value={queryData.query}
            />
            <Label>
              {errors.query && <p>Message is required!</p>}
              <p style={{ color: "#000" }}>{queryData.query.length}/256</p>
            </Label>
          </TextBox>

          <GenericButton type="submit" text="Send!" />
          {loading && (
            <div style={{ textAlign: "center" }}>
              <p>Sending...</p>
            </div>
          )}
        </Form>
      </Content>
    </GenericContainer>
  );
};

export default Contact;
