import React from "react";
import Lottie from "lottie-react";
import animFirst from "../../assets/animations/inicio3.json";
import { Container, ExternalBox } from "./backgroundFirst.styles";

const BackgroundFirst = () => {
  return (
    <Container>
      <ExternalBox>
        <Lottie
          animationData={animFirst}
          loop={true}
          autoplay={true}
          style={{ height: "600px" }}
        />
      </ExternalBox>
    </Container>
  );
};

export default BackgroundFirst;
