import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';

export const Container = styled(GenericContainer)`

`

export const InfoBlock = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-around;

    @media (max-width: ${variables.medium}px) {
        flex-direction: column-reverse;
    }

`

export const InfoBlock2 = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-around;

    @media (max-width: ${variables.medium}px) {
        flex-direction: ${props => props.ismobile ? 'column-reverse' : 'column'};
    }

`



export const AnimContainer = styled.div`

`




export const DataContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
    text-align:center;
`


export const Title = styled.h1`
    margin-top:30px;
    margin-bottom:0;
    padding:45px;
    font-size: 35px;
    color: #000000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom:0;

    @media (max-width: ${variables.medium}px) {
        margin-top: 0;
        margin-bottom:0;
        padding:0;
        font-size: 25px;
    }
`

export const SubTitle = styled.h3`
    margin-top:30px;
    padding:45px;
    padding-top:0;
    color: #000000;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size:18px;

    @media (max-width: ${variables.medium}px) {

    }

`

export const Content = styled(GenericContent)`
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;



`
export const TitleSection = styled.h1`
    margin-top:50px;
    font-size: 45px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 0;
    text-align: center;
`
