
import styled from 'styled-components';

export const ButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    margin: 15px 0;
    border:none;
    text-decoration:none;
    outline:none;
    margin:0 auto;
    background:transparent;
    


`

export const ButtonStyles = styled.div`

    position:relative;
    cursor:pointer;
    -webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);

    background: rgba(0,124,195,1);
    background: -moz-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,124,195,1)), color-stop(13%, rgba(0,124,195,1)), color-stop(98%, rgba(0,212,198,1)), color-stop(100%, rgba(0,212,198,1)));
    background: -webkit-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -o-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007cc3', endColorstr='#00d4c6', GradientType=1 );

    padding: 10px 35px;
    text-align: center;
    border-radius: 5px;
    max-width: 90px;

    color: #fdfdfd;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    transition:transform .05s;

    &:hover{
        transform:scale(1.03);
    }
    &:active {
        transform:scale(.95);
    }


`

export const ButtonText = styled.p`
    margin:0;
    color: #fdfdfd;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
`
