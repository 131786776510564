import React from "react";
import { useParams } from "react-router-dom";

const ProjectsPage = () => {
  const { id } = useParams();

  return <div>ProjectsPage works! ID: {id}</div>;
};

export default ProjectsPage;
