import React from 'react'
import { Container, Text,BottomLine } from './linkButton.styles'; 

const LinkButton = ({text}) => {
    
    return (
        <Container>
            <Text>
                { text }
            </Text>
            {/* <BottomLine/> */}
        </Container>
    )
}

export default LinkButton;