import styled from 'styled-components';
import { GenericContainer } from '../../global.styles';

export const Container = styled.div`
    z-index:-1;
    position:absolute;
    top:0;
    width:100%;
`

export const ExternalBox = styled.div`
    width:100%;
    height:600px;
    overflow:hidden;
    display:flex;
    justify-content:center;

`