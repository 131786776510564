import styled from 'styled-components';
import { GenericButton, GenericContainer } from '../../global.styles'

export const Container = styled(GenericContainer)`
    display:flex;
    flex-direction:column;


`

export const Content = styled.div`
    width:100%;
    max-width:1200px;
    display:flex;
    flex-direction:column;
`

export const Background = styled.div`
    background-image:url('${null}');
    height:80vh;
    background-repeat: no-repeat;
    background-position: right;
`

export const BackgroundImg = styled.img.attrs({
    src: null })`
    @media (max-width:1000px){
     display:none;
    }

`
export const Data = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    align-items:center;
    width:100%;

    @media (max-width:1000px){
        padding 15% 10%;
    }
`

export const GenericBackground = styled.div`
    position: absolute;
    width:100%;
    height:1350px;
    z-index: -1;
    top:40%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    background-image:url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


`