import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Content, Data, GenericBackground } from "./homePage.styles";

import First from "../../components/first/First";
import WhatWeDo from "../../components/whatWeDo/WhatWeDo";
import Projects from "../../components/projects/Projects";
import HowWeWork from "../../components/howWeWork/HowWeWork";
import WhoWeAre from "../../components/whoWeAre/WhoWeAre";
import Contact from "../../components/contact/Contact";
import background2 from "../../assets/images/backgrounds/background2.svg";

const Home = () => {
  const location = useLocation();

  React.useEffect(() => {
    console.log(location);
  }, [location]);

  return (
    <Container>
      <First />
      <WhatWeDo />
      <Projects />
      <HowWeWork />
      <WhoWeAre />
      <Contact />
      <GenericBackground src={background2} />
    </Container>
  );
};

export default Home;
