import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';


export const Container = styled(GenericContainer)`

`

export const Project = styled.div`

    margin: 10px;
    border-radius: 15px;

    overflow:hidden;
    background: rgb(14,105,255);
    background: -moz-linear-gradient(45deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    background: linear-gradient(45deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e69ff",endColorstr="#514599",GradientType=1);
    
    -webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    @media (max-width: ${variables.medium}px) {
        flex-direction:column;
        justify-content:space-between;
    }
`

export const TitleContainer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;

`

export const Title = styled.h1`
    font-size: 35px;
    color: #f1f1f1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 0;
    text-align: center;
    margin:0;
    @media (max-width: ${variables.medium}px) {
        font-size: 25px;
    }
`
export const TitleSection = styled.h1`
    font-size: 45px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 0;
    text-align: center;
    @media (max-width: ${variables.medium}px) {

        font-size: 35px;
    }
`


export const ProjectDescription = styled.h3`
    color: #f1f1f1;
    margin-right:50px;
    font-weight:400;
    font-size:18px;
    @media (max-width: ${variables.medium}px) {
        font-size: 14px;
        text-align:left;
        margin:0;

    }
`

export const ButtonContainer = styled.div`
    margin-right:50px;
    @media (max-width: ${variables.medium}px) {
        margin-right:10px;
    }

`

export const Button = styled.div`

`


export const Content = styled(GenericContent)`
    margin:60px 0;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;


`
export const ProjectsContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
`


export const ProjectImg = styled.div`
    width:100%;
    min-width: 300px;
    max-width: 400px;

    background-image:url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: ${variables.medium}px) {
        display:none;
    }

`

export const DataContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding:40px;
`