import styled from 'styled-components'
import background from '../../assets/images/backgrounds/404.svg';

import { GenericButton, GenericContainer, ButtonLink } from '../../global.styles'

export const Container = styled(GenericContainer)`


`

export const Content = styled.div`
    max-width:1200px;
    background-color:transparent;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
`

export const NavButton = styled.div`

`



export const ButtonsContainer = styled.div`
    display:flex;
    justify-content:flex-end;
`

export const LogoContainer = styled.div`
    display:flex;
    
`

export const Title = styled.h1`
    margin:10% 10px;
    margin-bottom:100px;
    font-size:50px;

`

export const NotFoundBackground = styled.div`
width:100vw;
height:100vh;
position:fixed;
top:0;
left:0;
z-index:-1;
overflow:hidden;
display:flex;
justify-content:center;
align-items:center;
opacity:.3;

background-image:url('${background}');
background-repeat: no-repeat;
background-position: center;
background-size: cover;

`