import React from 'react'
import { GenericContainer, GenericContent } from '../../global.styles';


const Loading = () => {
    
    return (
        <GenericContainer>
            <GenericContent>
                Loading Works!
            </GenericContent>
        </GenericContainer>
    )
}

export default Loading;