import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';
import {Link as LinkScroll} from "react-scroll";

export const LinkButton = styled.div`
    margin: 0 10px;
    p {

        font-family:'Poppins';
        font-weight:400;
        color:#fdfdfd;
    }
    cursor:pointer;



`

export const ContainerLink = styled.div`
    transition:transform .05s;

    &:hover{
        transform:scale(1.03);
    }
    &:active {
        transform:scale(.95);
    }
`


export const Container = styled.div`
    position:fixed;
    z-index:2;
    display:flex;
    justify-content:center;
    width:100%;
    background-color:#1154A8;

`

export const Links = styled.div`
    display:flex;
    justify-content:flex-end;


    @media (max-width: ${variables.medium}px) {
        display: none;
    }

`

export const Content = styled(GenericContent)`
    display:flex;
    justify-content:space-between;


`

export const Logo = styled.div`
    p {
        margin:0;
        margin-top:10px;
        font-size:20px;
        font-family:'Poppins';
        font-weight:700;
        color:#fdfdfd;
    }
    img {
        width: 60px;
        margin-top: 15px;
        margin-left:10px;
    }
    cursor:pointer;

`

export const BurgerContainer = styled.div`
    display:none;
    @media (max-width: ${variables.medium}px) {

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px 10px;
        margin:10px
        height: 20px;
        width:20px;
        z-index: 2;
        border-radius:100px
        cursor: pointer;
        transition:transform .05s;

        &:hover{
            transform:scale(1.03);
        }
        &:active {
            transform:scale(.95);
        }
    }




`

export const BurgerMenuBar = styled.div`
    width: 20px;
    height: 4px;
    background-color: #f1f1f1;
`
