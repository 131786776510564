import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/homePage/HomePage";
import ProjectsPage from "./pages/projectsPage/ProjectsPage";
import NotFound from "./pages/notFound/NotFound";

import Loading from "./components/loading/Loading";
import Navbar from "./components/navBar/NavBar";
import { GlobalStyle } from "./global.styles";

const App = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && <Loading />}
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/:id" element={<ProjectsPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
