import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';

export const Container = styled(GenericContainer)`
    background-image:url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

`

export const InfoBlock = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-around;
    @media (max-width: ${variables.medium}px) {
        flex-direction:column-reverse;

    }

`
export const InfoBlock2 = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-around;

    @media (max-width: ${variables.medium}px) {
        flex-direction: ${props => props.ismobile ? 'column-reverse' : 'column'};
    }

`

export const AnimContainer = styled.div`

`

export const Anim = styled.div`
    background-image:url('${props => props.src}');
    height: 200px;
    border-radius: 15px;
    width:300px;
    height:300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    background-color:#f2f2f2;

`

export const DataContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
    text-align:center;
`

export const Title = styled.h1`
    margin-top:30px;
    margin-bottom:0;
    padding:45px;
    font-size: 35px;
    color: #fdfdfd;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom:0;
    
    @media (max-width: ${variables.medium}px) {
        margin-top: 0;
        margin-bottom:0;
        padding:0;
        font-size: 30px;
    }
    
`

export const SubTitle = styled.h3`
    margin-top:30px;
    padding:45px;
    padding-top:0;
    color: #fdfdfd;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size:18px;
    text-align: center;

    @media (max-width: ${variables.medium}px) {

        font-size: 18px;
    }


`

export const Content = styled(GenericContent)`
    height:100%;
    width:100%;
    display:flex;
    margin-top:20px;
    flex-direction:column;
    


`

export const TitleSection = styled.h1`
    margin-top:50px;
    font-size: 45px;
    color: #f1f1f1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 0;
    text-align: center;
    @media (max-width: ${variables.medium}px) {

        font-size: 35px;
    }
`
