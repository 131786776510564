import styled from 'styled-components';
import { GenericContent, variables } from '../../global.styles';


export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;

`

export const Content = styled(GenericContent)`
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    text-align: right;

`
export const TextContainer = styled.div`
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: ${variables.medium}px) {
        align-items: center;

    }
`

export const Title = styled.h1`
    margin-top:80px;
    margin-bottom: 0;
    padding:45px;
    font-size: 65px;
    color: #fdfdfd;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom:0;

    @media (max-width: ${variables.medium}px) {
        font-size: 40px;
        padding:0px;
        margin-bottom:35px;
    }

`

export const SubTitle = styled.h3`
    margin-top:30px;
    padding:45px;
    padding-top:0;
    color: #fdfdfd;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    text-align: right;
    max-width:700px;

    span{
        font-family: 'Poppins';
        font-size:25px;
        font-style: bold;
        font-weight: 800;
    }
    
    @media (max-width: ${variables.medium}px) {
        margin-top:10px;
        text-align: center;
        font-size:15px;

    }
`

export const SubTitle2 = styled.h3`
    margin:0 auto;
    margin-top:30px;
    padding:45px;
    padding-top:0;
    color: #000000;
    font-size:25px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    max-width:800px;


    
`



export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 45px;
    @media (max-width: ${variables.medium}px) {
        justify-content: center;
        margin: 10px;

    }
    
`

export const ContactButton = styled.div`

    position:relative;
    -webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);

    background: rgba(0,124,195,1);
    background: -moz-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,124,195,1)), color-stop(13%, rgba(0,124,195,1)), color-stop(98%, rgba(0,212,198,1)), color-stop(100%, rgba(0,212,198,1)));
    background: -webkit-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -o-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    background: linear-gradient(45deg, rgba(0,124,195,1) 0%, rgba(0,124,195,1) 13%, rgba(0,212,198,1) 98%, rgba(0,212,198,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007cc3', endColorstr='#00d4c6', GradientType=1 );

    margin-right:45px;
    padding: 10px 35px;
    text-align: center;
    border-radius: 5px;
    max-width: 90px;

    color: #fdfdfd;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;


`
