import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';


export const Container = styled(GenericContainer)`
    background-image:url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

`


export const Content = styled(GenericContent)`
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;

`
export const Title = styled.h1`
    font-size: 45px;
    margin-top:60px;
    color: #f1f1f1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    text-align:center;
`

export const CardsContainer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;

    @media (max-width: ${variables.medium}px) {
        flex-direction:column;


    }

    
`


export const Card = styled.div`
    width:100%;
    max-width:300px;
    height:400px;
    margin: 10px;
    padding:10px;
    border-radius: 15px;
    background: rgb(241,241,241);
    background: -moz-linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(253,253,253,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(253,253,253,1) 100%);
    background: linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(253,253,253,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f1f1",endColorstr="#fdfdfd",GradientType=1);
    
    background-image:url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    -webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    overflow:hidden;

    @media (max-width: ${variables.medium}px) {
        margin: 10px auto;
        

    }
`


export const CardTitle = styled.h3`
    padding:10px;
    margin:0;
    color: #f1f1f1;
    font-family: 'Raleway';
    font-style: normal;
    text-align: center;  
`


export const CardDescription = styled.p`
    color: #f1f1f1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size:14px;
    text-align: center;
    margin-bottom:0;
`


export const CardImg = styled.div`
    background-image:url('${props => props.src}');
    height:100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;


`

export const CardData = styled.div`

    width:100%;
    border-radius:15px;

    background: rgb(14,105,255);
    background: -moz-linear-gradient(135deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    background: linear-gradient(135deg, rgba(14,105,255,1) 0%, rgba(81,69,153,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e69ff",endColorstr="#514599",GradientType=1);

    -webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
    box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);

    display:flex;
    flex-direction:column;
    align-items:center;
    
`



export const DescriptionContainer = styled.div`
background: #fff;
-webkit-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
-moz-box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);
box-shadow: 1px 0px 20px -4px rgba(0,0,0,0.64);



` 

export const Description = styled.p`
    padding:45px;
    color: #f1f1f1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size:22px;
    text-align: center;

`



export const BackgroundImg = styled.div`
    width:100%;
    background-image:url('${props => props.src}');

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


`

export const SocialLink = styled.a`
    color: #f1f1f1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-decoration:none;
    margin-bottom:10px;

    transition:transform .05s;

    &:hover{
        transform:scale(1.03);
    }
    &:active {
        transform:scale(.95);
    }
    


`


export const SocialLinkDesc = styled.h3`


`