import React from "react";
import { GenericContainer, GenericContent } from "../../global.styles";
import {
  Container,
  Content,
  Title,
  SubTitle,
  SubTitle2,
  ContactButton,
  ButtonContainer,
  TextContainer,
} from "./first.styles";
import { GenericButton } from "../button/Button";
import BackgroundFirst from "../backgroundFirst/BackgroundFirst";
import { Link, animateScroll as scroll } from "react-scroll";

const First = () => {
  return (
    <Container>
      <Content>
        <TextContainer>
          <Title>Hey! How's it going?</Title>
          <SubTitle>
            We are a software factory with young, informal, and disruptive
            professionals. We offer technological solutions ranging from design
            and development to implementation.
            <br />
            We are innovators, we are cutting-edge, we are{" "}
            <span>AvantCoders.</span>
          </SubTitle>
        </TextContainer>
        <ButtonContainer>
          <Link spy={true} smooth={true} duration={500} to={"contact"}>
            <GenericButton text="Contact Us" />
          </Link>
        </ButtonContainer>
        <SubTitle2>
          Our goal is to bring your digital products to life!
        </SubTitle2>
      </Content>
      <BackgroundFirst />
    </Container>
  );
};

export default First;
