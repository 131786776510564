
import styled , { createGlobalStyle } from 'styled-components';

export const variables = {
    large:1200,
    medium:768,
    small:320,

} 


export const GlobalStyle = createGlobalStyle`

`

export const GenericButton = styled.div`

`

export const GenericContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export const GenericContent = styled.div`
    width:100%;
    max-width:1200px;
    display:flex;
    flex-direction:row;
    justify-content:center;
`
