import React, { useState } from 'react'
import { ButtonText, ButtonStyles, ButtonContainer } from './button.styles';
import { useSpring, animated } from 'react-spring'



export const ButtonC = () => {

    const [on, toggle] = useState(false);

    const animation = useSpring({ transform: on ? 'scale(1.1)' : 'scale(1)' });
    
    
    return (
        <ButtonContainer>
            <animated.div
            >
                <ButtonStyles><ButtonText> Contactar! </ButtonText></ButtonStyles>
            </animated.div>
        </ButtonContainer>
    )
}


export const GenericButton = ({text}) => {

    const [on, toggle] = useState(false);

    const animation = useSpring({ transform: on ? 'scale(1.1)' : 'scale(1)' });
    
    return (

        <ButtonContainer>
            <animated.div


            style={animation} >
                    <ButtonStyles><ButtonText>{text}</ButtonText></ButtonStyles>
            </animated.div>
        </ButtonContainer>
    )
}
