import React from "react";
import Lottie from "lottie-react";
import { LottieContainer } from "./animContainer.styles";

import anim from "../../assets/animations/animDefault.json";
import animSitioWeb from "../../assets/animations/animSitioWeb.json";
import animWeSite from "../../assets/animations/animSitioWebV2.json";
import animWebApp from "../../assets/animations/animAplicacionesWebV2.json";
import animMobile from "../../assets/animations/animAplicacionesMobileV2.json";
import animDesign from "../../assets/animations/animDiseñoV2.json";
import animDev from "../../assets/animations/animDesarrolloV2.json";
import animDeploy from "../../assets/animations/animSubimosV2.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const AnimContainer = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animSitioWeb} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimSitioWeb = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animSitioWeb} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimDesign = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animDesign} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimDev = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animDev} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimDeploy = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animDeploy} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimWebSite = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animWeSite} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimWebApp = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animWebApp} loop={true} autoplay={true} />
    </LottieContainer>
  );
};

export const AnimMobile = () => {
  return (
    <LottieContainer>
      <Lottie animationData={animMobile} loop={true} autoplay={true} />
    </LottieContainer>
  );
};
