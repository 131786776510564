import styled from 'styled-components'
import  { variables } from '../../global.styles';


export const LottieContainer = styled.div`
    width:100%;
    @media (max-width: ${variables.medium}px) {
        width:60%
        margin:0 auto;
    }
`