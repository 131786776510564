import React,{ useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { Link, animateScroll as scroll } from "react-scroll";

import { ContainerLink } from './genericNavLink.styles';
import LinkButton from '../linkButton/LinkButton';


const GenericNavLink = ({text,link}) => {
    const [on, toggle] = useState(false);
    const animation = useSpring({ transform: on ? 'scale(1.1)' : 'scale(1)' });

    return(
        <ContainerLink>
            <Link spy={true} smooth={true} duration={500} to={link}>
                    <LinkButton text={text}/>
            </Link>
        </ContainerLink>
    )
}


export default GenericNavLink;