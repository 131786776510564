import styled from 'styled-components';

import { variables } from '../../global.styles';

export const Content = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    max-width:1200px;
    width:100%
`

export const SocialLinkBox = styled.div`
    margin: 10px 15px;
    display:flex;
    flex-direction:column;
    justify-content:left;
    align-items:center;

    img{
        margin:0 10px;
        fill:#fff;
        height:25px;
        color:#fff;
    }

    h3 {
        margin:0;
    }

    
`


export const ContactDataContainer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    a{
        text-decoration:none;
        color:#000;
    }

    @media (max-width:1000px){
        flex-direction:column;
        padding:0;
        font-size:22px;
        align-items:center;

        h3{
            margin:0;
            margin-bottom:10px;
        }
        

    }
`


export const TextBox = styled.div`
    display: flex;
    flex-direction:column;
    width:100%;
`

export const Input = styled.input`
    width: 100%;
    height:50px;
    font-size: 18px;
    font-family:'Raleway';
    color:#f1f1f1;
    border: none;
    text-align:center;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,.3);
    margin-bottom: 10px;
    background-color: #2F4E75;

    &:hover{
        box-shadow: 5px 6px 20px rgba(0,0,0,.3);
        transition: box-shadow 0.2s ease-in-out;
     
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #f1f1f1;
        opacity: 1;
    }
    
    &::-ms-input-placeholder { 
        color: #f1f1f1;
    }
    
    &::-ms-input-placeholder { 
        color: #f1f1f1;
    }
`
export const TextArea = styled.textarea`
    width: 100%;
    height:150px;
    font-size: 18px;
    font-family:'Raleway';
    color:#f1f1f1;
    border: none;
    text-align:center;
    background-color: #2F4E75;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.3);
    -moz-box-shadow: 0 2px 10px rgba(0,0,0,.3);
    margin-bottom: 10px;
    resize: none;
    padding: 1px 0;


    &:hover{
        box-shadow: 5px 6px 20px rgba(0,0,0,.3);
        transition: box-shadow 0.2s ease-in-out;
    
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #f1f1f1;
        opacity: 1;
    }
    
    &::-ms-input-placeholder { 
        color: #f1f1f1;
    }
    
    &::-ms-input-placeholder { 
        color: #f1f1f1;
    }
` 



export const Form = styled.form`
    margin-top: 50px;
    margin-bottom:100px;
    max-width:1200px;
    width:90%

`


export const Button = styled.button`
    width: 100%;
    height:50px;
    font-size: 18px;
    border: none;
    text-align:center;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,.3);
    margin-bottom: 10px;
    background-color: #fff;
    &:hover{
        box-shadow: 5px 6px 20px rgba(0,0,0,.3);
        transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out ;
        background-color: #E29045;
        color: #fff;

    }

    &:focus {
        outline: none;
      }
`

export const Label = styled.label`
      display:flex;
      justify-content: space-between;      
      p {
          margin:0;
          margin-bottom:10px;
          color:red;
      }

`



export const TitleSection = styled.h1`
    margin-top:50px;
    font-size: 45px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 0;
    text-align: center;

    @media (max-width: ${variables.medium}px) {

        font-size: 35px;
    }
`
