
import React , { useState }  from 'react' ;
import ReactDOM from 'react-dom';


const Modal = ({ children, activator  }) => {

    const [show, setShow] = useState(false)

    return (
      <>
        {activator({ setShow })}
        {show && (
            ReactDOM.createPortal(
                    React.cloneElement(children, { toggle: setShow, hide: () => setShow(false) }),
            document.getElementById('modal')
            )
        )}
    
      </>
    );
  }

export default Modal;
