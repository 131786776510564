import styled from 'styled-components';
import { GenericContent, GenericContainer, variables } from '../../global.styles';


export const ContainerLink = styled.div`
    transition:transform .05s;

    &:hover{
        transform:scale(1.03);
    }
    &:active {
        transform:scale(.95);
    }
`
